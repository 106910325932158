import { Component, OnInit } from '@angular/core';
import {AgendarComponent} from "../agendar/agendar.component";
import * as moment from "moment";

@Component({
  selector: 'app-reagendar',
  templateUrl: '../agendar/agendar.component.html',
  styleUrls: ['../agendar/agendar.component.scss']
})
export class ReagendarComponent extends AgendarComponent {
  id: number;

  ngOnInit(){
    this.id = this.route.snapshot.params.id;

    this.getInfo();
    this.calcMonth(0);
  }

  getInfo(){
    this.call.get(`delivery/reschedule/${this.id}`).subscribe(data => {
      this.data = data.return;
      this.data.address = '';
      this.data.latitude = null;
      this.data.longitude = null;
    });
  }

  send(){
    const self = this;
    this.call.put(`delivery/reschedule/${this.id}`, {
      start: moment(self.daySelected).startOf('day').add(self.hours == 0 ? '8' : 12, 'hours').toDate(),
      end: moment(self.daySelected).startOf('day').add(self.hours == 0 ? '12' : 18, 'hours').toDate(),
      confirmed_start: moment(self.daySelected).startOf('day').add(self.hours == 0 ? '8' : 12, 'hours').toDate(),
      confirmed_end: moment(self.daySelected).startOf('day').add(self.hours == 0 ? '12' : 18, 'hours').toDate(),
      phones: (self.data.phones || '') + ' ' + self.phone,
      workshift: self.hours == 0 ? 'morning' : 'evening',
      day: moment(self.daySelected).format('YYYY-MM-DD'),
      address: this.data.address,
      latitude: this.data.latitude,
      longitude: this.data.longitude,
      complement: this.data.complement,
    }).subscribe(data => {
      self.calcMonth(0);
      self.app.alert(`Confirmar Coleta`, `Coleta confirmada com sucesso!`, `success`).then(() => self.router.navigateByUrl(''))
    }, error => {
      self.calcMonth(0);
    });
  }
}
