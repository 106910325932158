import Api, {Io, Response} from './api';
import config from '../config';
import {Observable, Observer, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';

export type UserPermission = {
  userId: number;
  permission: string;
  field: string | null;
  key: string | null;
}

export type UserVariable = {
  userId: number;
  variable: string;
  field: string | null;
}

export type User = {
  id: number;
    cpf: string;
   register_date: Date;
   name: string | null;
   token: string | null;
   password: string;
   picture: string | null;
   provisoryPassword: string | null;
 email: string | null;
   emailValidated: boolean;
 phone: string | null;
   phoneValidated: boolean;
   active: boolean;
   master: boolean;
     Permissions: UserPermission[];
     Variables: UserVariable[];
};

@Injectable()
export default class Me {
    private load = new Subject();

    constructor(public api: Api) {
    }

    public async getCacheData(): Promise<User | false>{
        try{
            return JSON.parse(await localStorage.getItem(config.me.cache_name));
        }catch (e) {
            return false;
        }
    }

    get(cache = true, io ?: Io): Observable<User | false>{
        const self = this;
        return Observable.create( async (observer: Observer<User | false>) => {
            self.load.subscribe((data: User | false) => observer.next(data));

            if(cache){
                const cached = await self.getCacheData();
                if(cached){
                  observer.next(cached)
                }else{
                  self.refresh(io).subscribe(data => observer.next(data));
                }
            }else{
              self.refresh(io).subscribe(data => observer.next(data));
            }
        });
    }

    refresh(io ?: Io): Observable<User | false>{
      const self = this;

      return Observable.create( async (observer: Observer<User | false>) => {
        (io ? io : self.api.new()).silent().get('me').subscribe(async response => {
          await localStorage.setItem(config.me.cache_name, JSON.stringify(response.return));
          self.load.next(response.return);
          observer.next(response.return)
        });
      });
    }

    async update(args: any, io ?: Io): Promise<Response> {
        const self = this;
        const api: Io = io ? io : self.api.new();
        const data = await self.get().pipe(take(1)).toPromise();

        for(let item in args){
            data[item] = args[item];
        }

        const update = await api.put('me', args).pipe(take(1)).toPromise();

        if(update.success){
            await self.refresh().toPromise();
        }

        return update;
    }

    async login(username: string, password: string, io ?: Io): Promise<User | false>{
        const self = this;
        await localStorage.setItem(config.me.cache_name, JSON.stringify({cpf: username, password: password}));
        return await self.get(false, io).pipe(take(1)).toPromise();
    }

    async logout(){
      return await localStorage.removeItem(config.me.cache_name);
    }

    recoverPassword(field: string, io ?: Io){
      const self = this;
      const api: Io = io ? io : self.api.new().set('success', true);
      return api.get('me/recover-password/' + config.system.recover_password, {field: field});
    }

    updatePassword(form: {password: string, newPassword: string, confirmPassword: string}, io ?: Io){
      const self = this;
      const api: Io = io ? io : self.api.new().set('success', true);
      const post = api.put('me/change-password', form);

      post.subscribe(async data => {
        if(data.success){
          await localStorage.setItem(config.me.cache_name, JSON.stringify(data.return));
        }
      });

      return post;
    }

    hasPermission(permission: string, field ?: string, key ?: string) {
      const self = this;
      return Observable.create((observer: Observer<boolean>) => {
        self.get().subscribe(data => {

          if (!data) {
            observer.next(false);
          } else {
            let returned = false;

            if(data.master){
              returned = true;
              observer.next(true);
            }

            for (let item of data.Permissions) {
              let _return = false;

              if (item.permission == permission || item.permission == `MASTER`) {
                _return = true;
              }

              if (field) {
                _return = (item.field == field);
              }

              if (key) {
                _return = (item.key == key);
              }

              if (_return) {
                returned = true;
                observer.next(true);
              }
            }

            if (!returned) {
              observer.next(false);
            }
          }
        });
      });
    }
}
