export default {
  api: {
    production_link: 'https://api4.padraologistico.com.br/dashboard/',
    // development_link: 'https://padraologistico-administrativo-backend-x3ph2mahcq-uk.a.run.app/dashboard/',
    development_link: 'http://localhost:4000/dashboard/'
  },
  me: {
    cache_name: 'user',
    login_field: 'CPF'
  },
  system: {
    register: false,
    name: 'Padrão Logístico',
    copyright: '© 2020 Padrão Logístico',
    logo: 'assets/images/logo/logo.png',
    recover_password: 'sms'
  },
  developer: {
    name: 'Desenvolvimento Ninja',
    link: 'https://www.desenvolvimento.ninja'
  }
};
