import swal, {SweetAlertIcon} from "sweetalert2";

export default class App {
    constructor() {}

    alert(title: string, message: string, type: SweetAlertIcon = 'info') {
      return swal.fire(title, message, type);
    }

    confirm(title: string = 'Confirmação', message: string = 'Você tem certeza de que deseja fazer isso?', confirmButtonText = 'OK', cancelButtonText = 'Cancelar') {
        return new Promise(resolve => {
            swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                // cancelButtonColor: '#d33',
                confirmButtonText: confirmButtonText,
                cancelButtonText: cancelButtonText
            }).then((result) => {
                if (result.value) {
                    resolve(true);
                }
            });
        });
    }
}
