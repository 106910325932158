import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './pages/index/index.component';
import { AgendarComponent } from './pages/agendar/agendar.component';
import {AppLoaderComponent} from './components/app-loader/app-loader.component';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import Api from './helpers/api';
import Me from './helpers/me';
import App from './helpers/app';
import { NgxMaskModule } from 'ngx-mask'
import { NgZorroAntdModule, NZ_I18N, pt_BR } from "ng-zorro-antd";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ConsultarDocumentoComponent } from './pages/consultar-documento/consultar-documento.component';
import { ReagendarComponent } from './pages/reagendar/reagendar.component';

@NgModule({
    declarations: [
        AppComponent,
        IndexComponent,
        AgendarComponent,
        AppLoaderComponent,
        ConsultarDocumentoComponent,
        ReagendarComponent
    ],
  imports: [
    GooglePlaceModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgZorroAntdModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    {
			provide: NZ_I18N,
			useValue: pt_BR,
		},
    Api,
    Me,
    App
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
