import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import Api from '../../helpers/api';
import App from '../../helpers/app';

@Component({
  selector: 'app-consultar-documento',
  templateUrl: './consultar-documento.component.html',
  styleUrls: ['./consultar-documento.component.scss']
})
export class ConsultarDocumentoComponent {
  mobile = window.innerWidth < 1000;
  passwordVisible = false;
  call = this.api.new();

  formData = {
    document: null,
    password: null
  };

  constructor(public api: Api, public route: ActivatedRoute, public app: App, public router: Router) {
    const self = this;
    self.formData.document = self.route.snapshot.params.id;
  }

  get isDisabled() {
    const self = this;
    return [
      !!self.formData.document,
      !!self.formData.password
    ].includes(false);
  }

  onReset() {
    const self = this;

    self.formData = {
      document: null,
      password: null
    };
  }

  onSubmit() {
    const self = this;
    const document = self.formData.document;
    const password = self.formData.password;

    self.call.post(`site/document-query/${document}/${password}`).subscribe(() => {
      window.location.href = `${self.api.url}site/document-query/${document}/${password}`;
    }, error => {
      self.app.alert('Documento não encontrado', error.message, 'error');
    });
  }
}
