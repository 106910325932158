import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {IndexComponent} from './pages/index/index.component';
import {AgendarComponent} from './pages/agendar/agendar.component';
import { ConsultarDocumentoComponent } from './pages/consultar-documento/consultar-documento.component';
import {ReagendarComponent} from "./pages/reagendar/reagendar.component";


const routes: Routes = [
  {
    path: '',
    component: IndexComponent
  },
  {
    path: 'consulta-documento/:id',
    component: ConsultarDocumentoComponent
  },
  {
    path: 'reagendar/:id',
    component: ReagendarComponent
  },
  {
    path: ':enterprise/:id',
    component: AgendarComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
