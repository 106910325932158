import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import Api from '../../helpers/api';
import App from '../../helpers/app';
import * as moment from "moment";
import { Moment } from 'moment';

@Component({
  selector: 'app-agendar',
  templateUrl: './agendar.component.html',
  styleUrls: ['./agendar.component.scss']
})
export class AgendarComponent {
  addM = 0;
  moment = moment;
  daySelected: Date = null;
  disabledDays: Record<string, Date> = {};
  calendar: {week: number, days: Moment[]}[] = [];

  hours = null;
  meses = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
  ];

  mobile = window.innerWidth < 1000;
  call = this.api.new();

  data:any = {};
  contract = null;
  enterprise = null;
  phone = '';

  constructor(public api: Api, public route: ActivatedRoute, public app: App, public router: Router) {
  }

  ngOnInit(){
    this.contract = this.route.snapshot.params.id;
    this.enterprise = this.route.snapshot.params.enterprise;

    this.getInfo();
    this.calcMonth(0);
  }

  get googleInitiated(){
    return window["google"];
  }

  getInfo(){
    this.call.get(`delivery/confirm/${this.enterprise}/${this.contract}`).subscribe(data => {
      this.data = data.return;
      this.data.address = '';
      this.data.latitude = null;
      this.data.longitude = null;
    });
  }

  isDayDisabled(day: moment.Moment) {
    const self = this;

    const conditions = [
      self.disabledDays[moment(day).format('YYYY-MM-DD')] != null,
      moment(day).isBefore(moment()),
      moment(day).weekday() == 0,
    ];

    return conditions.includes(true);
  }

  isToday(day: moment.Moment) {
    return moment(day).format('DD/MM/YYYY') == moment().format('DD/MM/YYYY');
  }

  calcMonth(add) {
    const self = this;

    const startMonth = moment().add(add, 'month').startOf('month');
    const endMonth = moment().add(add, 'month').endOf('month');
    const route = `delivery/days/${self.enterprise}`;

    self.call.post(route, {
      startDate: startMonth.format('YYYY-MM-DD'),
      endDate: endMonth.format('YYYY-MM-DD')
    }).subscribe(response => {
      if (response.success) {
        self.disabledDays = response.return;
        self.selectFirstAvailableDay();

        const startWeek = startMonth.week();
        const endWeek = endMonth.week();
        self.calendar = [];

        for (let week = startWeek; week < endWeek; week++) {
          self.calendar.push({
            week: week,
            days: Array(7).fill(0).map((n, i) => {
              return moment().week(week).startOf('week').clone().add(n + i, 'day');
            })
          });
        }

        self.addM = add;
      }
    });
  }

  selectDay(day: Date){
    const self = this;
    if(moment(day).isAfter(moment())){
      self.daySelected = moment(day).toDate();
    }
  }

  selectFirstAvailableDay() {
    const self = this;
    const firstDate = moment().add(1, 'day');
    const disabledDaysKey = Object.keys(self.disabledDays);

    for (let index = 0; index < disabledDaysKey.length; index++) {
      const dateKey = disabledDaysKey[index];
      if (dateKey == firstDate.format('YYYY-MM-DD')) {
        firstDate.add(1, 'day');
      }
    }

    self.selectDay(firstDate.toDate());
  }

  send(){
    const self = this;
    this.call.put(`delivery/confirm/${this.enterprise}/${this.contract}`, {
      start: moment(self.daySelected).startOf('day').add(self.hours == 0 ? '8' : 12, 'hours').toDate(),
      end: moment(self.daySelected).startOf('day').add(self.hours == 0 ? '12' : 18, 'hours').toDate(),
      confirmed_start: moment(self.daySelected).startOf('day').add(self.hours == 0 ? '8' : 12, 'hours').toDate(),
      confirmed_end: moment(self.daySelected).startOf('day').add(self.hours == 0 ? '12' : 18, 'hours').toDate(),
      phones: (self.data.phones || '') + ' ' + self.phone,
      workshift: self.hours == 0 ? 'morning' : 'evening',
      day: moment(self.daySelected).format('YYYY-MM-DD'),
      address: this.data.address,
      latitude: this.data.latitude,
      longitude: this.data.longitude,
      complement: this.data.complement,
    }).subscribe(data => {
      self.calcMonth(0);
      self.app.alert(`Confirmar Coleta`, `Coleta confirmada com sucesso!`, `success`).then(() => self.router.navigateByUrl(''))
    }, error => {
      self.calcMonth(0);
    });
  }

  fillAddress(address) {
    if(address && Array.isArray(address.address_components)){
      for(let item of address.address_components){
        if(item.types.indexOf('street_number') > -1){
          this.data.address = address.formatted_address;
          this.data.latitude = address.geometry.location.lat();
          this.data.longitude = address.geometry.location.lng();
          return true;
        }
      }
    }

    // Se não achar número

    this.data.address = null;
    this.data.latitude = null;
    this.data.longitude = null;
    return this.app.alert(`Erro`, `Por favor, selecione um endereço com número válido!`, `error`)
  }
}
